import React, { useEffect } from "react"
import { Helmet } from 'react-helmet';
import Layout from "../components/layout";
import Link from 'gatsby-link';

const NotFoundPage = () => {
	useEffect(() => {
	document.getElementById('lang-switch').href="/404/"
	}, []);
	return (
	<>
	<Helmet>
	<meta name="robots" content="noindex" />
	</Helmet>
	<Layout>
	<div className="container has-text-centered my-5" style={{textAlign:'center'}}>
		<h1 className="mt-5 mb-4 display-3 spec-h">404<br /> NOT FOUND</h1>
		<br />
			 <p className="lead text-danger">You just hit a route that doesn&#39;t exist... the sadness.<br /><b> Click the button below to return to the shop</b></p>
			<br />

		<Link to="/"><b style={{fontSize:'1.2em'}} className="btn btn-primary">Go back...</b></Link>
		<br />
	</div>
	</Layout>
	</>
)
}

export default NotFoundPage
